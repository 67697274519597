
$(document).ready(function () {
//    console.log("custom ok");


    // Mise à jour de la page produit (déclinaison modifiée etc...)
    if (typeof prestashop !== 'undefined') {
    // on verifie que la variable prestashop est définie
        prestashop.on(
            'updatedProduct',   // on capture l'événement de mise à jour de la fiche produit (changement de déclinaisons etc...)
            function(response){
                // console.log("Before updateProductPageInfos");
                // console.log(response.product_attributes);
                //console.log('combination ID : ' + prestashop['product']['current_combination']['id']);
                // console.log(response.currentTarget);
                //console.log(response.product);
            //    console.log("quantité : " + response.quantity);
            //    console.log("orderable : " + response.allow_oosp);          

               // variables pour la mise à jour du titre, référence, taille
                var id_attribute = response.id_product_attribute;
                var ref = response.product_reference;
                var attributes = response.product_attributes;
                var title = response.product_name;

                // variable stock disponible et vente sur commande ou non (pour le bouton ajouter au panier)
                var qte = response.quantity;
                var orderable = response.allow_oosp;

                // on déclenche la mise à jour de fiche produit
                updateProductPageInfos(title, id_attribute, ref, attributes);
                updateAddToCartButton(qte, orderable);
            });
        }

        const productPage = document.getElementById("product");
        var destination = '';
        var mobile = new Boolean(false);

        if($('#product-full-desc-mobile').is(':visible')){
            // console.log('bloc mobile visble');
            destination = document.getElementById("product-full-desc-mobile");
            mobile = true;
        }
        if($('#product-full-desc-desktop').is(':visible')){
            // console.log('bloc destktop visble');
            destination = document.getElementById("product-full-desc-desktop");
        }

        // const destination = document.getElementById("product-full-desc");
        if(productPage){
            // console.log('on est sur la product page');
            $("#voir-plus").on("click", function(e) {
                e.preventDefault();

                    if(mobile){
                      $('#desc-btn').click();
                    }

                  $('html, body').animate({
                    scrollTop: $(destination).offset().top
                }, 5000);
              });
        }    
});


function updateProductPageInfos(title, id_attribute, ref, attributes){

        for(const attributeId in attributes){
            // console.log('boucle for : ' + attributeId);
            var name = attributes[attributeId]['name'];
            //var group = attributes[attributeId]['group'];
            // console.log(group + ' : ' + name);
        }


        $('#product-info').find('p').html(id_attribute);
        $('#product-info').find('.reference').html(ref);
       // $('#product-info').find('.titre').html(title + ' - ' + group + ' ' + name );
        // $('h1').find('.title-info').html(' - ' + group + ' ' + name );
        $('h1').find('.title-size').text(name);

};

function updateAddToCartButton(qte, orderable){
    // on masque tout les boutons
     $('.main-add-to-cart-btn').hide();

    if(qte > 0){
        $('#btn-add').show();
        // on a du stock on affiche add to cart
    }else{
        // on a pas de stock
        // commande hors stock autorisée ou non
        switch(orderable){
            case 0:  // pas proposé sur commande
            $('#btn-available-soon').show();
            break;

            default: // on propose sur commande
            $('#btn-pre-order').show();
        }
    }
     

}

// popover bouton finaliser ma commande
$(document).ready(function() {
    $('#btnPopover').popover({
      html: true,
      content: function() {
        return $('#contenu-cacher').html();
      }
    });
    
  });



/*  Search Filter -> placement du bloc sur pc uniquement*/
/* tablet and desktop */

// $(document).ready(() => {
//     if ($(window).width() >= 768) {
//         let len = (('#desktop_search_filters_wrapper').length);
//         console.log('test len : ' + len);
//         if ((`#desktop_search_filters_wrapper`).length) {
//             $('#_desktop_search_filters_wrapper').prependTo('#products');
//             // $('#_desktop_search_filters_wrapper').css('display', 'block');
//         }

//     }
// });

// $(document).ready(() => {

//     if (document.getElementById("search_filter_toggler").style.display = "none") {
//         console.log("Filtrer ABS");
//     } else {
//         console.log("Filter Visible");
//     }

// });

// if (window.matchMedia("(min-width: 400px)").matches) {
//     /* the view port is at least 400 pixels wide */
//     console.log('viewport > 400');
// } else {
//     console.log('viewport < 400')
//     /* the view port is less than 400 pixels wide */
// }